/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-max-combinators */

.vue-file-agent {
  padding: 1rem !important;
  border: 1px solid $c-black-40 !important;

  .file-preview-wrapper {
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    padding: $spacing / 2 0;

    &::before {
      background-color: $c-black-20 !important;
    }
  }

  .file-preview-new {
    &:before {
      background-color: transparent !important;
    }
  }

  .file-preview-wrapper .file-preview:before {
    background-color: $c-black-20 !important;
  }

  .file-preview-overlay {
    background: none !important;
  }

  &.file-input-wrapper.is-drag-over,
  .is-drag-over &.file-input-wrapper {
    border-color: $c-black-80 !important;
    box-shadow: inset 0 0 20px 1px $c-black !important;
  }

  &.file-input-wrapper.is-drag-valid.is-drag-over,
  .is-drag-valid.is-drag-over &.file-input-wrapper {
    border-color: $c-black-80 !important;
    box-shadow: inset 0 0 20px 1px $c-black !important;
  }
}

.form-quote {
  .file-preview,
  .file-preview-overlay {
    background: none !important;
  }

  svg:not(.icon):not(.vs__open-indicator),
  .file-size,
  .file-preview::before,
  .file-preview-wrapper::before,
  .thumbnail,
  .help-text,
  .file-preview-new,
  .image-dimension {
    content: none !important;
    display: none !important;
  }
}
