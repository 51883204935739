
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































































































.hero {
  position: relative;
  padding-top: 1.6rem;

  @include fluid(
    padding-bottom,
    (
      s: 36px,
      xl: 80px,
    )
  );

  &:not(.hero-with-picture) {
    @include fluid(
      padding-top,
      (
        s: 36px,
        xl: 70px,
      )
    );
  }

  ::v-deep {
    [class*='action--'][class*='--bubble'] {
      .action__label {
        color: $c-white;
      }

      .action__arrow {
        background-color: $c-white;
        transform: rotate(90deg);

        svg {
          stroke: $c-dark-blue;
        }
      }
    }
  }
}

.hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hero-with-picture & {
    @include center-xy;

    z-index: 5;
  }
}

.hero__content__label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__content__title {
  max-width: 80rem;
  color: $c-dark-blue;

  ::v-deep {
    b,
    span {
      @extend %fw-light;

      color: $c-yellow;
    }
  }
}

.hero__content__label__tag {
  @extend %tag;
}

.hero__content__label__date {
  @extend %ff-alt;
  @extend %fw-normal;

  color: $c-black-60;
  font-size: 1.3rem;
  line-height: 2.6rem;
  letter-spacing: 0.5px;
}

.hero__content__title,
.hero__content__headline {
  text-align: center;

  .hero-with-picture & {
    color: $c-white;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.hero__content__headline {
  @extend %fw-medium;
}

.hero__content__baseline {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 26px,
      xl: 30px,
    )
  );

  max-width: 43rem;
  text-align: center;
  letter-spacing: 0.5px;

  @include mq($until: m) {
    margin-inline: 2rem;
  }
}

.hero__content__headline + .hero__content__title {
  @include fluid(
    margin-top,
    (
      s: 8px,
      xl: 3px,
    )
  );

  @include mq(m) {
    margin-bottom: 25px;
  }
}

.hero__content__label + .hero__content__title {
  @include fluid(
    margin-top,
    (
      s: 20px,
      xl: 40px,
    )
  );
}

.hero__content__title + .hero__content__baseline {
  @include fluid(
    margin-top,
    (
      s: 24px,
      xl: 28px,
    )
  );

  color: $c-black-80;
}

.hero__content__btn {
  @include fluid(
    margin-top,
    (
      s: 25px,
      xl: 50px,
    )
  );
}

.hero__background {
  @include fluid(
    height,
    (
      s: 515px,
      xl: 585px,
    )
  );
  @include fluid(
    border-radius,
    (
      s: 24px,
      xl: 40px,
    )
  );

  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  background-color: $c-dev-info;

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
}

.hero__background__picture {
  @include get-all-space;

  object-fit: cover;
}

.hero__lang {
  position: absolute;
  z-index: 14;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  ul {
    @extend %list-nostyle;

    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: $c-white;
    border-radius: 3rem;
  }

  a {
    @extend %fw-semi-bold;

    display: inline-flex;
    padding: 1rem 3rem;
    color: $c-black-80;
    font-size: 1.4rem;
    line-height: 2.6rem;
    text-decoration: none;
    letter-spacing: 0.5px;
    background-color: transparent;
    border-radius: 3rem;
    transition: all 0.3s ease-in-out;

    &.router-link-active,
    &:hover {
      color: $c-white;
      background-color: $c-dark-blue;
    }
  }
}
