// Before wrapper mq = 24 available columns
// After wrapper mq = 22 available columns
.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width-max;
  padding-right: 2.2rem;
  padding-left: 2.2rem;

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
  }
}
