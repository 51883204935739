
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.header {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;

  @include mq(m) {
    height: 12rem;
  }
}

.header-inner {
  display: flex;
  padding-top: 1.6rem;

  @include mq(m) {
    padding-top: 4rem;
  }
}

.header__logo__link {
  z-index: 15;
  position: absolute;
  top: 0;
  left: 50%;

  width: 15rem;
  padding: 2rem 0 1rem;
  background-color: $c-white;
  transform: translateX(-50%);

  @include mq(m) {
    width: 20rem;
    padding: 5rem 0 1rem;
  }
}

.nav__trigger {
  z-index: 10;
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;
  background-color: $c-black-40;
  border-radius: 999em;
  cursor: pointer;
}
