
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.contact-cta {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 130px,
    )
  );

  ::v-deep {
    [class*='action--'] {
      background-color: $c-dark-blue;

      .action__label {
        color: $c-white;
      }

      svg {
        path {
          fill: $c-white;
        }
      }
    }
  }
}

.contact-cta-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contact-cta__headline {
  @extend %fw-medium;

  margin-top: auto;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}

.contact-cta__title {
  margin-bottom: 2.6rem;
  color: $c-dark-blue;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;
    }
  }
}

.contact-cta__text {
  margin-inline: auto;
  max-width: 60rem;
  color: $c-content;
  letter-spacing: 0.5px;
}

.contact-cta__action {
  @include fluid(
    margin-top,
    (
      s: 20px,
      xl: 45px,
    )
  );
}
