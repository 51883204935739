// IMPORT GOOGLE FONTS
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&family=Roboto+Mono:wght@400;500&display=swap');

html {
  font-size: calc(1em * 0.625);
}

body {
  color: $c-body;
  font-family: $ff-default;
  font-size: $fs-body;
  font-weight: 400;
  line-height: 1.65;

  @include mq(m) {
    font-size: $fs-body-m;
  }
}
