
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















.credits {
  margin-bottom: 9rem;
}

.credits-wrapper {
  display: flex;
  align-items: baseline;
  padding: 3.6rem 0;
  border-top: 1px solid $c-black-40;
  border-bottom: 1px solid $c-black-40;
}

.credits__label {
  @include fluid(
    font-size,
    (
      s: 10px,
      xl: 11px,
    )
  );
  @include fluid(
    margin-right,
    (
      s: 20px,
      xl: 70px,
    )
  );
  @extend %ff-alt;

  margin-right: 2rem;
  color: $c-black-60;
  font-size: 1.1rem;
  line-height: 2.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.credits__text {
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 26px,
      xl: 30px,
    )
  );

  color: $c-content;
  letter-spacing: 0.5px;
}
