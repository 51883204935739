
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































.duo-picture {
  margin-bottom: 9rem;
}

.duo-picture-wrapper {
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
  }
}

.duo-picture__title-wrapper {
  @include mq(m) {
    width: col(10, 24);
    padding-inline: col(1, 24);
  }
}

.duo-picture__text-wrapper {
  @include mq(m) {
    width: col(14, 24);
    padding-inline: col(1, 24);
  }
}

.duo-picture__title {
  @include fluid(
    font-size,
    (
      xxs: 32px,
      xxl: 44px,
    )
  );

  color: $c-dark-blue;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;

      // color: $c-yellow;
    }
  }

  @include mq($until: m) {
    margin-bottom: 3rem;
  }
}

.duo-picture__text {
  padding-top: 1.2rem;
  color: $c-content;
  letter-spacing: 0.5px;

  ::v-deep {
    p {
      @include fluid(
        margin-bottom,
        (
          s: 20px,
          xl: 30px,
        )
      );
    }
  }
}

.duo-picture__description {
  margin-top: 2rem;
}

.duo-picture__ctas {
  display: flex;
  gap: 1rem;
}

.duo-picture__picture-outer {
  @include aspect-ratio(1, 1);
}

.duo-picture__picture__picture {
  @include get-all-space;

  object-fit: cover;
  border-radius: 4rem;
}
