
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















.lead {
  margin-bottom: 9rem;
}

.lead-wrapper {
  display: flex;
}

.lead__text {
  @include fluid(
    font-size,
    (
      s: 18px,
      xl: 22px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 36px,
      xl: 42px,
    )
  );

  color: $c-content;
  letter-spacing: 0.5px;
}
