html {
  font-size: calc(1em * 0.625);
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  display: block;
  margin: 0;
  font-family: $ff-default;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 200;
}

h4,
.h4,
h5,
.h5 {
  font-weight: 300;
}

h6,
.h6 {
  font-weight: 400;
}

h1,
.h1 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 36px, xxl: 70px));
  @include fluid(
    line-height,
    (
      xxs: 48px,
      xxl: 90px,
    )
  );
}

h2,
.h2 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 32px, xxl: 56px));
  @include fluid(
    line-height,
    (
      xxs: 40px,
      xxl: 66px,
    )
  );
}

h3,
.h3 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 28px, xxl: 44px));
  @include fluid(
    line-height,
    (
      xxs: 38px,
      xxl: 56px,
    )
  );
}

h4,
.h4 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xxl: 28px));
  @include fluid(
    line-height,
    (
      xxs: 28px,
      xxl: 38px,
    )
  );
}

h5,
.h5 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 24px));
  @include fluid(
    line-height,
    (
      xxs: 28px,
      xxl: 34px,
    )
  );

  letter-spacing: 0.5px;
}

/* stylelint-disable-next-line no-duplicate-selectors */
h6,
.h6 {
  // prettier-ignore
  @include fluid(font-size, (xxs: 14px, xxl: 16px));
  @include fluid(
    line-height,
    (
      xxs: 22px,
      xxl: 24px,
    )
  );

  letter-spacing: 0.5px;
}

.lead {
  // prettier-ignore
  @include fluid(font-size, (xxs: 16px, xxl: 22px));
  @include fluid(
    line-height,
    (
      xxs: 28px,
      xxl: 42px,
    )
  );

  color: $c-black-80;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.subtitle {
  @extend %ff-alt;

  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, xxl: 15px));

  color: $c-blue;
  font-weight: 300;
  line-height: 2.8rem;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.small {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.tiny {
  font-size: 1.2rem;
  line-height: 2.4rem;
}

.small-caps {
  font-variant: small-caps;
}
