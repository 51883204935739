
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































.flexible {
  > div {
    &:first-child,
    &:first-child > div {
      margin-top: 0;
    }

    &:last-child,
    &:last-child > div {
      margin-bottom: 0;
    }
  }
}
