
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































.footer {
  position: relative;
  width: 100%;
}

.footer-inner {
  padding: 10rem col(1, 20) 5rem;

  @include mq($until: m) {
    padding-top: 5rem;
  }
}

.footer__section {
  position: relative;
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
  }
}

.footer__section + .footer__section {
  margin-top: 10rem;

  @include mq(m) {
    margin-top: 20rem;
  }
}

.footer__contact {
  color: $c-dark-blue;

  h4 {
    @extend %fw-normal;
  }

  @include mq($until: m) {
    display: flex;
    align-items: center;
    flex-direction: column;

    h4 {
      text-align: center;
    }
  }

  @include mq(m) {
    width: col(8, 20);
    margin-right: col(1, 20);
  }
}

.footer__contact__btn {
  margin-top: 3rem;

  ::v-deep {
    padding: 0.6rem 0;
  }
}

.footer__nav {
  flex: 1;

  @include mq($until: m) {
    margin-top: 7rem;
  }
}

.footer__legal {
  @extend %ff-alt;

  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include mq($until: m) {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    text-align: center;
  }
}

.footer__legal__link {
  color: $c-black;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $c-yellow;
  }

  @include mq($until: m) {
    margin-top: 1.6rem;
  }

  @include mq(m) {
    margin-right: 2rem;

    &:first-child {
      margin-right: 0;
    }
  }
}

.footer__social {
  @include mq($until: m) {
    display: flex;
    justify-content: center;
  }

  @include mq(m) {
    margin-left: auto;
  }
}

.footer__lang {
  @include mq(m) {
    position: absolute;
    top: 16.4rem;
    left: 0;
  }
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  @extend %ff-alt;

  padding: 0;
  border: 0;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  color: $c-black;
  text-decoration: none;
  background: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $c-yellow;
    background: none;
  }
}
