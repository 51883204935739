
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































































































































.collapse {
  @include fluid(
    padding-bottom,
    (
      s: 24px,
      xl: 32px,
    )
  );

  position: relative;

  & + & {
    @include fluid(
      padding-top,
      (
        s: 24px,
        xl: 32px,
      )
    );

    border-top: 1px solid $c-black-40;
  }

  .collapse__content--text & {
    padding-bottom: 0;
  }
}

.collapse__header {
  position: relative;
  padding-right: col(1, 24);
  padding-left: col(1, 24);
  cursor: pointer;
}

.collapse__header__label {
  @extend %fw-medium;

  padding-right: 2rem;
  color: $c-dark-blue;
  line-height: 1.5;
}

.collapse__header__icon {
  @include center-y;
  @include fluid(
    width,
    (
      s: 12px,
      xl: 16px,
    )
  );
  @include fluid(
    height,
    (
      s: 12px,
      xl: 16px,
    )
  );

  right: col(1, 24);
}

.collapse-inner {
  @extend %fw-normal;

  overflow: hidden;
  padding-right: col(1, 24);
  padding-left: col(1, 24);
  color: $c-black-80;
  font-size: 1.4rem;
  line-height: 2.6rem;
}

[class*='collapse__content--'] {
  @include fluid(
    padding-top,
    (
      s: 24px,
      xl: 32px,
    )
  );

  ::v-deep(p) {
    margin-bottom: $spacing;
  }

  ::v-deep(a) {
    color: $c-yellow;
    text-decoration: underline;
  }
}

.collapse__content-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: col(1, 24);
  padding-bottom: 2.4rem;
  padding-left: col(1, 24);

  & + & {
    padding-top: 2.4rem;
    border-top: 1px solid $c-black-40;
  }
}

.collapse__content--files__btn {
  margin-top: $spacing * 2.5;
  margin-left: col(1, 24);
}

.file__content {
  display: flex;
  align-items: center;
}

.file__type {
  @extend %tag;
}

.file__label-container {
  @include mq($until: m) {
    display: flex;
    flex-direction: column;
  }
}

.file__label,
.file__type {
  margin-right: 2.4rem;
}

.file__size {
  @extend %ff-alt;

  color: $c-black;
  font-size: 1.1rem;
}

.file__download {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.file__download__label {
  color: $c-black;
  font-size: 1.2rem;
  text-decoration: none;

  @include mq($until: m) {
    display: none;
  }
}

.file__download__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-left: 2rem;
  background-color: $c-button;
  border-radius: 999em;
  transition: background 0.3s ease-in-out;

  .file__download:hover & {
    background-color: $c-yellow;
  }
}

.collapse__content--patent {
  li {
    position: relative;
    padding-bottom: 2.5rem;
    font-size: 1.4rem;
    line-height: 2.6rem;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: -24px;
      width: 0.5rem;
      height: 0.5rem;
      background: $c-yellow;
      border-radius: 50%;
    }
  }
}
