
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































.download-link-inner {
  @include fluid(
    margin-bottom,
    (
      s: 56px,
      xl: 80px,
    )
  );

  display: flex;
  background-color: $c-black-20;
  border-radius: 24px;

  @include mq($until: m) {
    display: flex;
    flex-direction: column;
  }
}

.download-link__content {
  @include fluid(
    padding-top,
    (
      s: 30px,
      xl: 40px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      s: 30px,
      xl: 40px,
    )
  );
  @include fluid(
    padding-left,
    (
      s: 30px,
      xl: 50px,
    )
  );
  @include fluid(
    padding-right,
    (
      s: 30px,
      xl: 50px,
    )
  );

  flex: 1;
}

.download-link__btn-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 7rem;
  background-color: $c-yellow;
  border-radius: 24px;

  @include mq(m) {
    width: 20rem;
  }
}

.download-link__label {
  color: $c-black;
  font-size: 1.2rem;
  text-decoration: none;
}

.download-link__btn {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-left: 2rem;
  background-color: $c-button;
  border-radius: 999em;
  transition: transform 0.3s ease-in-out;

  .download-link__btn-wrapper:hover & {
    transform: scale(1.1);

    .icon {
      animation: slide 0.4s linear;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  45% {
    transform: translateY(6rem);
    opacity: 0;
  }

  55% {
    transform: translateY(-6rem);
  }

  75% {
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.download-link__link {
  @include get-all-space;
}

.download-link__headline {
  font-size: 1.1rem;
}

.download-link__title {
  margin-bottom: 2.7rem;

  @include mq(m) {
    margin-bottom: 4rem;
  }
}

.download-link__type {
  @extend %tag;
}

.download-link__language {
  font-size: 1.4rem;
}

.download-link__size {
  @extend %ff-alt;

  color: $c-black;
  font-size: 1.1rem;
}

.download-link__language,
.download-link__size {
  margin-right: 2.4rem;
}
