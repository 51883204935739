
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.flexible-picture {
  margin-bottom: 9rem;
}

.flexible-picture-outer {
  @include aspect-ratio(85, 54);
  @include fluid(
    border-radius,
    (
      s: 24px,
      xl: 40px,
    )
  );

  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  background-color: $c-dev-info;
}

.flexible-picture__picture {
  @include get-all-space;

  object-fit: cover;
}
