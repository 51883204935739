
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































.video-popup {
  @include get-all-space;

  position: fixed;
  z-index: 999;
  background: rgba($c-black, 0.5);

  ::v-deep {
    [class*='action--'] {
      position: absolute;
    }
  }
}

.video-popup__button {
  top: $spacing;
  right: $spacing * 1.5;
}

.video-popup__content {
  @include center-xy;

  width: 100%;
  background: $c-black;
}

.video-popup__content-inner {
  width: 70vw;
  margin: auto;
}

.video-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
