
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































.big-cta-video {
  position: relative;
  padding-top: 1.6rem;
  padding-bottom: 4rem;

  ::v-deep {
    .action__icon-wrapper {
      width: 1.6rem;
      height: 1.6rem;
      margin-left: 0.8rem;

      svg {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: $c-yellow;
      }
    }
  }
}

.big-cta-video__content {
  @include center-xy;

  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.big-cta-video__background {
  @include aspect-ratio(16, 9);
  @include fluid(
    border-radius,
    (
      s: 24px,
      xl: 40px,
    )
  );

  position: relative;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  background-color: $c-dev-info;

  &::after {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
}

.big-cta-video__background__picture {
  @include get-all-space;

  object-fit: cover;
}
