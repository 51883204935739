
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































































































































@import '~flickity/dist/flickity.css';

.gallery {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 150px,
    )
  );

  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    width: col(2, 24);
    height: 100%;
    background: linear-gradient(
      to right,
      rgba($c-white, 1) 0%,
      rgba($c-white, 0) 100%
    );
  }

  &::after {
    top: 0;
    right: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    top: 0;
    left: 0;
  }

  ::v-deep {
    .gallery__card__play {
      position: absolute;
      z-index: 2;
      right: 2rem;
      bottom: 2rem;
      padding: 1.8rem 1.6rem 1.6rem 2rem;
      line-height: 1;
    }

    .action__icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.gallery-wrapper {
  @include mq($until: s) {
    .gallery__card {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;
      margin: 0 0 $spacing;
    }
  }

  @include mq(s) {
    display: flex;
  }
}

.gallery__card {
  @include fluid(
    margin-right,
    (
      s: 40px,
      xl: 76px,
    )
  );

  &:nth-child(odd) {
    width: col(18, 24);

    .gallery__card__picture-outer {
      @include aspect-ratio(530, 350);
    }

    @include mq(m) {
      width: calc(col(10, 24) - 8rem);
    }
  }

  &:nth-child(even) {
    width: col(14, 24);

    .gallery__card__picture-outer {
      @include aspect-ratio(400, 490);
    }

    @include mq(m) {
      width: calc(col(8, 24) - 8rem);
    }
  }
}

.gallery__card__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 3rem;
  background-color: $c-black-50;
  border-radius: 2rem;
}

.gallery__card__picture__picture {
  @include get-all-space;

  object-fit: cover;
}

.gallery__card__title {
  @extend %fw-medium;

  margin-bottom: 1rem;
  color: $c-dark-blue;
}

.gallery__card__text {
  @include line-clamp($number: 4);

  color: $c-black-80;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
}
