
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.nav {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;

  > .nav__item {
    @include mq($until: m) {
      border-bottom: 1px solid $c-black-40;
    }

    @include mq(m) {
      width: 12rem;
    }
  }

  @include mq($until: m) {
    flex-direction: column;
  }
}

.sub-nav {
  margin: 1.8rem 0 0;
  padding: 0;
  list-style-type: none;
}

.nav__item {
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  line-height: 2.6rem;
  letter-spacing: 0.5px;
}

.nav__link {
  @extend %fw-semi-bold;

  color: $c-dark-blue;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  .sub-nav & {
    @extend %fw-normal;

    color: $c-black-80;

    &:hover {
      color: $c-yellow;
    }
  }
}
