
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































































.burger-menu {
  position: fixed;
  z-index: 5;
  top: 6rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 6rem);
  transform-origin: 0 0;
  background: $c-white;

  &.opened {
    overflow: auto;
  }

  @include mq(m) {
    top: 0;
    height: 100vh;
    background-color: $c-yellow;
  }

  @include mq(xxxl) {
    background: linear-gradient(90deg, $c-white 50%, $c-yellow 50%);
  }
}

.burger-menu__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: col(1, 12);
  padding-left: col(1, 12);

  @include mq(m) {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(xl) {
    flex-flow: wrap;
    flex-direction: row;
  }

  @include mq(xxxl) {
    height: 100%;
  }
}

.burger-menu__nav,
.burger-menu__secondary-nav {
  @extend %list-nostyle;
}

.burger-menu__secondary-nav {
  margin-top: 5rem;
}

.burger-menu__nav__item {
  border-bottom: 1px solid $c-black-40;

  .burger-menu__secondary-nav & {
    border-bottom: 0;
  }
}

.burger-menu__nav__item__link {
  @extend %fw-semi-bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  color: $c-dark-blue;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  text-decoration: none;

  &.router-link-active {
    color: $c-yellow;
  }

  .burger-menu__secondary-nav & {
    @extend %fw-normal;

    padding-top: 0;
    padding-bottom: 1.6rem;
    color: $c-black;
  }
}

.burger-menu__social {
  margin-top: 10rem;
  justify-content: center;

  ::v-deep {
    .socials__item {
      margin-right: 2rem;
    }

    .socials__svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

// TRANSITION

.slide-fade-enter-active {
  transition: all 0.3s $ease-out-quad;
}

.slide-fade-leave-active {
  transition: all 0.3s $ease-out-quad;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
