
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































































































































































.transition-manager {
  display: flex;
  flex-direction: column;

  > div:first-child {
    flex: 1;
  }

  &.entering [class*='transition-enter'] {
    @extend %visually-hidden;
  }
}

.transition__curtain {
  @include get-all-space;

  position: fixed;
  z-index: 1001;
  display: block;
  background: white;
  transform: scaleY(0);
  transform-origin: bottom;

  .none & {
    display: none;
  }

  .middle & {
    transform-origin: top;
  }

  .onboard-ready & {
    display: block;
    transform: none;
  }
}

.transition__curtain-content {
  @include center-xy;

  display: none;

  .onboard-ready & {
    display: block;
  }
}

.transition__curtain__logo {
  width: 7rem;
  margin: 0 auto $spacing * 2.5;
  opacity: 0;
  transform: scale(1.5);
}
