
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































.nav-wrapper {
  width: 100%;
}

.nav {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  width: 100%;
}

.nav__item {
  @include fluid(
    margin-right,
    (
      s: 30px,
      xl: 60px,
    )
  );

  @extend %fw-semi-bold;

  position: relative;
  display: none;
  font-size: 2rem;
  line-height: 1.2;

  .nav-secondary & {
    @extend %fw-normal;

    margin-right: 0;
  }

  @include mq(m) {
    display: inline-flex;
    font-size: 14px;
    line-height: 46px;
    letter-spacing: 0.5px;

    &:nth-child(4) {
      margin-left: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.nav__item__link {
  position: relative;
  color: $c-dark-blue;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s $ease-out-quad;

  > span {
    padding: 3rem 0;
  }

  &:hover,
  &.is-open,
  &.router-link-active {
    color: $c-yellow;
  }
}

.nav__trigger {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;
  background-color: transparent;
  border-radius: 999em;
  cursor: pointer;
  transition: background-color 0.3s $ease-out-quad;

  &.is-open,
  &:hover {
    background-color: $c-black-40;
  }
}

.nav-secondary {
  @extend %shadow;

  position: absolute;
  top: 4rem;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $c-white;

  .nav__item {
    margin-left: 0;
  }

  .nav__item__link {
    white-space: nowrap;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s $ease-softer;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-2rem);
}
