
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































$bp: l;

.anchor-content {
  @include fluid(
    margin-top,
    (
      s: 80px,
      xl: 120px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include mq($bp) {
    flex-direction: row;
    align-items: flex-start;
    gap: 16rem;
  }
}

.anchor-content__anchors,
.anchor-content__anchors__header {
  display: flex;
  flex-direction: column;
}

.anchor-content__anchors {
  gap: 4rem;
  background-color: $c-white;

  @include mq(l) {
    position: sticky;
    z-index: 2;
    top: 12rem;
    flex: 1;
    flex-shrink: 0;
  }
}

.anchor-content__anchors__header {
  gap: 2rem;
}

.anchor-content__anchors__header__title {
  color: $c-black;
  font-weight: 500;
}

.anchor-content__anchors__header__description {
  color: $c-content;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 3rem;
  letter-spacing: 0.05rem;
}

.anchor-content__anchors__items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.anchor-content__anchors__items__item {
  display: flex;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $c-black-40;

  svg {
    flex-shrink: 0;
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-yellow;
  }
}

.anchor-content__anchors__items__item__link {
  color: $c-black-50;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-decoration: none;
  letter-spacing: 0.05rem;
  transition: color 0.15s;

  &:hover,
  .is-active & {
    color: $c-blue;
  }
}

.anchor-content__items,
.anchor-content__items__item,
.anchor-content__items__item__content {
  display: flex;
  flex-direction: column;
}

.anchor-content__items {
  gap: 3rem;

  @include mq($bp) {
    gap: 6rem;
  }

  @include mq(l) {
    flex: 1;
    flex-shrink: 0;
  }
}

.anchor-content__items__item {
  gap: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $c-black-40;
}

.anchor-content__items__item__title {
  color: $c-content;
  font-weight: 600;
}

.anchor-content__items__item__content {
  gap: 2rem;
  margin-bottom: 0;

  ::v-deep {
    ul {
      margin: 0;
    }

    li {
      padding-bottom: 0;
      color: $c-content;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 3rem;
      letter-spacing: 0.05rem;
    }
  }
}
