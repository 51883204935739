
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































































































.app {
  padding-top: 5rem; // SAME AS HEADER

  &:before {
    @include get-all-space;

    position: fixed;
    height: 11rem;

    z-index: 10;
    // transform: translateY(4.5rem);
    pointer-events: none;
    content: '';
    background: linear-gradient(
      180deg,
      #ffffff 49.48%,
      rgba(255, 255, 255, 0) 100%
    );

    @include mq(m) {
      height: 18rem;
    }
  }

  @include mq(m) {
    padding-top: 12rem;
  }
}
