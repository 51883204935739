
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













.quote {
  margin-bottom: 10rem;
}

.quote__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.quote__icon {
  width: 2.5rem;

  @include mq(m) {
    width: 3rem;
    // margin-right: 3rem;
  }
}

.quote__text {
  padding: 3.4rem 0;
  color: $c-black;
  opacity: 0.5;
}

.quote__author {
  @extend %fw-normal;

  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 14px,
    )
  );

  color: $c-black;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.5px;
}
