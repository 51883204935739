
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.lang {
  display: inline-flex;
  flex-direction: column;
  margin-top: 6rem;
  border: 1px solid $c-black-40;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
}

.lang__current {
  padding: 1.6rem 3rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  span {
    color: $c-dark-blue;
  }

  svg {
    margin-left: 2rem;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;

    .open & {
      transform: rotate(-90deg);
    }
  }
}

.lang__nav {
  padding: 1.6rem 3rem;

  ul {
    @extend %list-nostyle;
  }

  [class*='router-link-'],
  a {
    padding: 0.5rem 0;
    display: block;
    color: $c-dark-blue;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $c-yellow;
    }
  }

  [class*='router-link-'][class*='-active'] {
    @extend %fw-semi-bold;
  }
}

// Transition
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s $ease-softer;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
