
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































.related-contents {
  // @include fluid(
  //   margin-top,
  //   (
  //     s: 120px,
  //     xl: 180px,
  //   )
  // );
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  position: relative;
}

.related-contents__content__title {
  @extend %fw-normal;

  color: $c-dark-blue;

  @include mq(m) {
    margin-left: 4rem;
  }
}

.related-contents-inner {
  display: flex;
  align-items: flex-start;
  // More than 2
  flex-wrap: wrap;

  @include mq($until: m) {
    flex-direction: column;
    margin-top: 2.4rem;
  }

  @include mq(m) {
    margin-top: 4.5rem;
  }
}

.related-contents__card {
  width: 100%;

  &:nth-child(even) {
    @include mq(m) {
      flex: col(10, 24);
      margin-top: 6rem;
      margin-left: col(1, 24);

      ::v-deep {
        .content-card-inner {
          @include aspect-ratio(1, 1);
        }
      }
    }
  }

  &:nth-child(odd) {
    @include mq(m) {
      flex: col(13, 24);
    }
  }
}
