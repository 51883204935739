
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.not-found {
  ::v-deep {
    .hero {
      padding-bottom: 0;
      b {
        display: block;
      }
    }
  }
}
