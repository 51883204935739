
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































.socials {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.socials__item {
  margin-right: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.socials__link {
  color: currentColor;
  text-decoration: none;
}

.socials__svg {
  display: block;
  width: 2rem;
  height: 2rem;
  fill: currentColor;
  transition: fill 0.3s ease-in-out;

  .socials__link:hover & {
    fill: $c-yellow;
  }
}
