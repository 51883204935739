
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































// Defaults
.action,
[class*='action--'] {
  color: $c-black;
  cursor: pointer;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: $c-black;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  padding: 0.5rem 3rem;
  font-size: 1.3rem;
  line-height: 4.6rem;
  text-align: center;
  letter-spacing: 0.5px;
  background: $c-button;
  border: 0;
  border-radius: 1rem;
  transition: background 0.3s ease-in-out;

  &:focus {
    background: rgba($c-dark-blue, 0.2);
  }

  &[disabled] {
    color: $c-black-40;
  }

  @include mq(m) {
    padding: 0.6rem 5rem;
  }
}

[class*='action--'][class*='--btn'][class*='--arrow'] {
  @extend %fw-semi-bold;

  color: $c-dark-blue;

  .action__arrow {
    position: absolute;
    top: 8px;
    right: 21px;

    svg {
      &:first-child {
        opacity: 0;
        transform: translateX(28px);
        transition: 0.3s $ease-softer;
      }

      &:last-child {
        opacity: 0;
        transform: translateX(-32px);
        transition: 0.3s $ease-softer;
      }
    }
  }

  @include mq(l) {
    .action__label {
      transition: 0.3s $ease-softer;
    }

    &:not(button.disabled) {
      &:hover {
        // background: $c-yellow;

        .action__label {
          transform: translateX(-14px);
        }

        .action__arrow {
          svg {
            &:first-child {
              opacity: 1;
              transform: translateX(0);
            }

            &:last-child {
              opacity: 1;
              transform: translateX(-14px);
            }
          }
        }
      }
    }
  }
}

[class*='action--'][class*='--btn'][class*='--arrow'][class*='--icon'] {
  // @include mq($until: l) {
  //   .action__label,
  //   .action__arrow {
  //     display: none;
  //   }
  // }

  .action__icon-wrapper {
    display: none;
  }
}

[class*='action--'][class*='--bubble'] {
  @extend %fw-semi-bold;

  position: relative;
  display: inline-flex;
  align-items: center;
  color: $c-dark-blue;
  text-decoration: none;
}

[class*='action--'][class*='--bubble'][class*='--arrow'] {
  .action__arrow {
    margin-left: 1.7rem;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 4rem;
    height: 4rem;
    background-color: $c-yellow;
    border-radius: 999em;
    overflow: hidden;

    svg {
      path {
        fill: $c-white;
      }

      &:first-child {
        transform: translateX(0);
        transition: 0.3s $ease-softer;
      }

      &:last-child {
        transform: translateX(-12px);
        transition: 0.3s $ease-softer;
      }
    }
  }

  @include mq(l) {
    .action__label {
      font-size: 1.4rem;
      transition: 0.3s $ease-softer;
    }

    &:hover {
      .action__arrow {
        // svg {
        //   &:first-child {
        //     opacity: 0;
        //     transform: translateX(28px);
        //     transition: 0.3s $ease-softer;
        //   }

        //   &:last-child {
        //     opacity: 0;
        //     transform: translateX(-32px);
        //     transition: 0.3s $ease-softer;
        //   }
        // }
        svg {
          &:first-child {
            animation: slideBar 0.4s linear;
          }

          &:last-child {
            animation: slideArrow 0.4s linear;
          }
        }
      }
    }
  }
}

@keyframes slideBar {
  0% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(2.8rem);
    opacity: 0;
  }

  55% {
    transform: translateX(-2.8rem);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideArrow {
  0% {
    transform: translateX(-1.2rem);
  }

  45% {
    transform: translateX(-3.2rem);
    opacity: 0;
  }

  55% {
    transform: translateX(3.2rem);
    opacity: 1;
  }

  100% {
    transform: translateX(-1.2rem);
  }
}

.action__icon {
  fill: currentColor;
}
