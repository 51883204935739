.wysiwyg,
[class*='wysiwyg--'] {
  @include fluid(
    margin-bottom,
    (
      xxs: 50px,
      m: 100px,
    )
  );
  letter-spacing: 0.5px;

  h1,
  .h1,
  h2,
  .h2 {
    @include fluid(
      margin-bottom,
      (
        xxs: 30px,
        m: 40px,
      )
    );
  }

  h2,
  .h2 {
    @include fluid(
      font-size,
      (
        xxs: 18px,
        xxl: 24px,
      )
    );
    @include fluid(
      line-height,
      (
        xxs: 28px,
        xxl: 36px,
      )
    );
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    @include fluid(
      font-size,
      (
        xxs: 14px,
        xxl: 16px,
      )
    );
    @include fluid(
      line-height,
      (
        xxs: 22px,
        xxl: 24px,
      )
    );
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        m: 30px,
      )
    );
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));

    color: $c-content;

    &.lead {
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 60px, m: 80px));
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 4rem;
    margin-bottom: 6rem;
    padding-left: 2.4rem;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    position: relative;
    padding-bottom: 2.5rem;
    font-size: 1.4rem;
    line-height: 2.6rem;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: -24px;
      width: 5px;
      height: 5px;
      background: $c-yellow;
      border-radius: 50%;
    }
  }

  ol li {
    position: relative;
    padding-bottom: 2.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-black;
    }
  }

  a {
    color: $c-black;
    text-decoration: none;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-black-80;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-black-40;
    }

    td {
      padding: 3rem 0;
    }
  }
}
