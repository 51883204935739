
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































.content-card {
  @include mq($until: l) {
    margin-bottom: 2rem;

    ::v-deep {
      .content-card__btn {
        padding: 1.8rem;
        line-height: 1;
      }

      .action__icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.content-card-inner {
  @include aspect-ratio(3, 2);

  position: relative;
  overflow: hidden;
  border-radius: 2.4rem;
}

.content-card__content {
  @include fluid(
    margin,
    (
      s: 30px,
      xl: 48px,
    )
  );

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-card__headline {
  @include fluid(
    font-size,
    (
      s: 11px,
      xl: 12px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 10px,
      xl: 16px,
    )
  );

  color: $c-white;
}

.content-card__title {
  @include line-clamp();
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 24px,
    )
  );

  max-width: 41rem;
  color: $c-white;
}

.content-card__picture {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
  background-color: $c-black-80;
  transition: 0.5s $ease-out-quad;

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  }

  img {
    @include get-all-space;

    object-fit: cover;
    transition: 0.3s $ease-softer;
  }

  .content-card.active & {
    img {
      transform: scale(1.1);
    }
  }
}

.content-card__btn-wrapper {
  display: inline-flex;
  margin-top: auto;
}
