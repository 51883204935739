
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.duo {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  position: relative;
}

.duo-inner {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
  }

  @include mq(m) {
    background-color: $c-black-20;
    border-radius: 4rem;
  }
}

.duo__content {
  @include fluid(
    padding-top,
    (
      s: 38px,
      xl: 68px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      s: 38px,
      xl: 68px,
    )
  );

  position: relative;
  order: 1;
  width: 100%;

  @include mq($until: m) {
    text-align: center;
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: col(12, 24);
    padding-right: col(2, 24);
    padding-left: col(1, 24);
  }
}

.duo__content__title {
  @extend %fw-normal;

  color: $c-dark-blue;
  letter-spacing: 1px;
}

.duo__content__text {
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 26px,
      xl: 30px,
    )
  );

  margin-top: $spacing * 1.5;
  margin-bottom: 4.8rem;
  color: $c-content;
  letter-spacing: 0.5px;

  ::v-deep {
    p {
      margin-bottom: 0;
    }
  }
}

.duo__picture {
  order: 0;
  width: 100%;

  @include mq($until: m) {
    margin-top: 1.5rem;
  }

  @include mq(m) {
    width: col(12, 24);
  }
}

.duo__picture-outer {
  @include aspect-ratio(635, 450);
}

.duo__picture__picture {
  @include get-all-space;

  object-fit: cover;
  border-radius: 4rem;
}
