
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































.burger-submenu {
  cursor: pointer;
}

.burger-submenu-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-right: col(2, 24);
  padding-left: col(2, 24);
  background-color: $c-white;
}

.burger-submenu__back__btn {
  margin-right: 1.8rem;

  svg {
    transform: rotate(180deg);
  }
}

.burger-submenu__back__label {
  color: $c-black;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.burger-submenu__items {
  @extend %list-nostyle;

  margin-top: 1.8rem;
}

.burger-submenu__item {
  border-bottom: 1px solid $c-black-40;
}

.burger-submenu__link {
  @extend %fw-semi-bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  color: $c-black;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  text-decoration: none;

  &.router-link-active {
    color: $c-yellow;
  }
}

// TRANSITION

.slide-fade-enter-active {
  transition: all 0.3s $ease-out-quad;
}

.slide-fade-leave-active {
  transition: all 0.3s $ease-out-quad;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
