
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































































.highlight {
  @include fluid(
    padding-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  @include mq($until: m) {
    padding-top: 2rem;
  }
}

.highlight-wrapper {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    width: col(2, 24);
    height: 100%;
    background: linear-gradient(
      to right,
      rgba($c-white, 1) 0%,
      rgba($c-white, 0) 100%
    );
  }

  &::after {
    top: 0;
    right: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    top: 0;
    left: 0;
  }
}

.highlight-slider {
  position: relative;
}

.highlight-slider__list {
  @extend %list-nostyle;

  display: flex;
  margin-right: -1rem;
  margin-left: -1rem;

  @include mq(m) {
    margin-right: -2.5vw;
    margin-left: -2.5vw;
  }
}

.highlight-slider__wrapper {
  overflow: hidden;
}

.highlight-slider__item {
  @include fluid(
    height,
    (
      s: 200px,
      xl: 500px,
    )
  );

  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: col(22, 24);
  opacity: 0;
  // transition: all 0.5s $ease-out-quad;
  transition: all 0.8s $custom-animation;

  &.is-left,
  &.is-right {
    opacity: 1;
  }

  &.is-center {
    z-index: 2;
    opacity: 1;
  }

  &.is-left {
    transform: translateX(-150%) scale(0.85);
  }

  &.is-right {
    transform: translateX(50%) scale(0.85);
  }

  .slider-articles & {
    @include mq($until: m) {
      height: auto;
    }
  }

  @include mq(m) {
    width: col(16, 24);
  }
}

.h-slider-controls {
  display: none;

  @include mq(m) {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    width: col(16, 24);
    transform: translate(-50%, -50%);
  }
}

[class*='h-slider-controls__item--'] {
  @extend %shadow;

  width: 5rem;
  height: 5rem;
  cursor: pointer;
  background-color: $c-white;
  border: 0;
  border-radius: 1.2rem;
  transition: background-color 0.5s $ease-out-quad;

  ::v-deep {
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &[class*='--prev'] {
    transform: translateX(-50%);
  }

  &[class*='--next'] {
    transform: translateX(50%);
  }

  &:hover {
    background-color: $c-yellow;
  }
}

.highlight-slider__picture-outer {
  @include fluid(
    height,
    (
      s: 200px,
      xl: 500px,
    )
  );

  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 100%;
  background-color: $c-black-50;
  border-radius: 3.5rem;

  .slider-articles & {
    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background-color: rgba(black, 0.5);
      // background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
    }
  }
}

.highlight-slider__picture {
  @include get-all-space;

  object-fit: cover;
}

.highlight-slider__content {
  margin-top: 3rem;
  opacity: 0;
  transition: all 0.8s $custom-animation;

  .is-center & {
    opacity: 1;
  }

  @include mq(m) {
    @include center-xy;

    z-index: 5;
    width: 75%;
    margin-top: 0;
    text-align: center;
    transform: translate(-50%, -5rem);

    .is-center & {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.highlight-slider__content__headline {
  @extend %fw-medium;
  @include fluid(
    font-size,
    (
      s: 11px,
      xl: 12px,
    )
  );

  margin-bottom: 1.6rem;
  color: $c-yellow;
}

.highlight-slider__content__title {
  @include mq(m) {
    @include line-clamp($number: 3);

    color: $c-white;
  }
}

.highlight-slider__content__btn {
  margin-top: 3rem;

  .action__label {
    @extend %fw-semi-bold;

    font-size: 1.3rem;
  }
}
